<template>
  <div>
    <div class="pageSettingsMain">
      <div class="mainView" v-if="currView == 'mainView'">
        <p class="pageSettingsTitle">Theme Settings</p>

        <div class="settings-elements">
          <div
            class="d-flex justify-content-between align-items-center singleElement"
            @click="changeCurrView('progressBar')"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8335 1.69167V1.70833V3.375C14.4918 3.825 17.0835 7.15 16.6335 10.8083C16.2502 13.8417 13.8668 16.25 10.8335 16.6083V18.275C15.4168 17.8167 18.7502 13.75 18.2918 9.16667C17.9168 5.20833 14.7752 2.08333 10.8335 1.69167ZM9.16683 1.71667C7.54183 1.875 5.99183 2.5 4.72516 3.55L5.91683 4.78333C6.85016 4.03333 7.97516 3.55 9.16683 3.38333V1.71667ZM3.55016 4.725C2.50016 5.99167 1.87516 7.53333 1.7085 9.16667H3.37516C3.5335 7.98333 4.00016 6.85833 4.74183 5.91667L3.55016 4.725ZM12.9168 7.08333L8.85016 11.15L7.0835 9.38333L6.20016 10.2667L8.85016 12.9167L13.8002 7.96667L12.9168 7.08333ZM1.71683 10.8333C1.8835 12.4667 2.52516 14.0083 3.5585 15.275L4.74183 14.0833C4.0085 13.1417 3.5335 12.0167 3.3835 10.8333H1.71683ZM5.91683 15.3083L4.72516 16.45C5.9835 17.5 7.5335 18.1583 9.16683 18.3333V16.6667C7.9835 16.5167 6.8585 16.0417 5.91683 15.3083Z"
                  fill="#73738D"
                />
              </svg>
              Progress Bar
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"  @click="changeCurrView('animation')"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4998 1.66667C14.0469 1.66667 15.5307 2.28125 16.6246 3.37521C17.7186 4.46917 18.3332 5.9529 18.3332 7.5C18.3332 9.75833 17.0498 11.7083 15.1832 12.6833C14.6248 13.75 13.7498 14.625 12.6832 15.1833C11.7082 17.05 9.75817 18.3333 7.49984 18.3333C5.95274 18.3333 4.46901 17.7188 3.37505 16.6248C2.28109 15.5308 1.6665 14.0471 1.6665 12.5C1.6665 10.2417 2.94984 8.29167 4.8165 7.31667C5.37484 6.25 6.24984 5.375 7.3165 4.81667C8.2915 2.95 10.2415 1.66667 12.4998 1.66667ZM9.99984 15.8333C8.45274 15.8333 6.96901 15.2188 5.87505 14.1248C4.78109 13.0308 4.1665 11.5471 4.1665 10C3.6415 10.7 3.33317 11.5583 3.33317 12.5C3.33317 13.6051 3.77216 14.6649 4.55356 15.4463C5.33496 16.2277 6.39477 16.6667 7.49984 16.6667C8.4415 16.6667 9.29984 16.3583 9.99984 15.8333ZM12.4998 13.3333C10.9527 13.3333 9.46901 12.7188 8.37505 11.6248C7.28109 10.5308 6.6665 9.0471 6.6665 7.5C6.1415 8.2 5.83317 9.05833 5.83317 10C5.83317 11.1051 6.27216 12.1649 7.05356 12.9463C7.83496 13.7277 8.89477 14.1667 9.99984 14.1667C10.9415 14.1667 11.7998 13.8583 12.4998 13.3333ZM12.4998 3.33333C11.5582 3.33333 10.6998 3.64167 9.99984 4.16667C11.5469 4.16667 13.0307 4.78125 14.1246 5.87521C15.2186 6.96917 15.8332 8.4529 15.8332 10C16.3582 9.3 16.6665 8.44167 16.6665 7.5C16.6665 6.39493 16.2275 5.33512 15.4461 4.55372C14.6647 3.77232 13.6049 3.33333 12.4998 3.33333ZM8.33317 7.5C8.33317 8.60507 8.77216 9.66488 9.55356 10.4463C10.335 11.2277 11.3948 11.6667 12.4998 11.6667C12.9998 11.6667 13.4748 11.5833 13.9165 11.4167C14.0832 10.975 14.1665 10.5 14.1665 10C14.1665 8.89493 13.7275 7.83512 12.9461 7.05372C12.1647 6.27232 11.1049 5.83333 9.99984 5.83333C9.49984 5.83333 9.02484 5.91667 8.58317 6.08333C8.4165 6.525 8.33317 7 8.33317 7.5Z"
                  fill="#73738D"
                />
              </svg>
              Animation
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"
            @click="changeCurrView('colorScheme')"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.76667 15.8333L4.16667 14.2333L10.8833 7.5L12.5 9.11667M17.2583 4.69167L15.3083 2.74167C15 2.41667 14.4583 2.41667 14.1333 2.74167L11.5333 5.34167L9.925 3.75L8.75 4.925L9.93333 6.10833L2.5 13.5417V17.5H6.45833L13.8917 10.0667L15.075 11.25L16.25 10.075L14.65 8.475L17.25 5.875C17.5833 5.54167 17.5833 5 17.2583 4.69167Z"
                  fill="#73738D"
                />
              </svg>

              Color Scheme
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"
            @click="changeCurrView('font')"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1667 6.66667H16.6667V16.6667H17.5V17.5H14.1667V16.6667H15V14.1667H11.6667L10.4167 16.6667H11.6667V17.5H8.33333V16.6667H9.16667L14.1667 6.66667ZM15 7.5L12.0833 13.3333H15V7.5ZM4.16667 2.5H8.33333C9.25833 2.5 10 3.24167 10 4.16667V13.3333H7.5V9.16667H5V13.3333H2.5V4.16667C2.5 3.24167 3.24167 2.5 4.16667 2.5ZM5 4.16667V7.5H7.5V4.16667H5Z"
                  fill="#73738D"
                />
              </svg>
              Font
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"  @click="changeCurrView('layout')" v-if="false"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33317 1.66667H16.6665C17.1085 1.66667 17.5325 1.84227 17.845 2.15483C18.1576 2.46739 18.3332 2.89131 18.3332 3.33334V16.6667C18.3332 17.1087 18.1576 17.5326 17.845 17.8452C17.5325 18.1577 17.1085 18.3333 16.6665 18.3333H3.33317C2.43317 18.3333 1.6665 17.5833 1.6665 16.6667V3.33334C1.6665 2.89131 1.8421 2.46739 2.15466 2.15483C2.46722 1.84227 2.89114 1.66667 3.33317 1.66667ZM3.33317 3.33334V9.16667H9.1665V3.33334H3.33317ZM3.33317 16.6667H9.1665V10.8333H3.33317V16.6667ZM16.6665 16.6667V10.8333H10.8332V16.6667H16.6665ZM16.6665 3.33334H10.8332V9.16667H16.6665V3.33334Z"
                  fill="#73738D"
                />
              </svg>

              Layout
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"
            @click="goToConfiguration('product_match')"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 5.83333V9.16666H13.3333V10.8333H10.8333V4.16666H12.5L10 0.833328L7.5 4.16666H9.16667V10.8333H6.66667V9.10833C7.25 8.8 7.66667 8.20833 7.66667 7.5C7.66667 6.48333 6.84167 5.66666 5.83333 5.66666C4.81667 5.66666 4 6.48333 4 7.5C4 8.20833 4.41667 8.8 5 9.10833V10.8333C5 11.2754 5.17559 11.6993 5.48816 12.0118C5.80072 12.3244 6.22464 12.5 6.66667 12.5H9.16667V15.0417C8.575 15.3417 8.16667 15.9583 8.16667 16.6667C8.16667 17.1529 8.35982 17.6192 8.70364 17.963C9.04745 18.3068 9.51377 18.5 10 18.5C10.4862 18.5 10.9525 18.3068 11.2964 17.963C11.6402 17.6192 11.8333 17.1529 11.8333 16.6667C11.8333 15.9583 11.425 15.3417 10.8333 15.0417V12.5H13.3333C13.7754 12.5 14.1993 12.3244 14.5118 12.0118C14.8244 11.6993 15 11.2754 15 10.8333V9.16666H15.8333V5.83333H12.5Z"
                  fill="#73738D"
                />
              </svg>

              Product Match
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"
            @click="goToConfiguration('scoreQuiz')"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5835 14.1667L15.4168 18.3333L12.5002 15.4167L13.7502 14.1667L15.4168 15.8333L18.3335 12.9167L19.5835 14.1667ZM5.00016 1.66667C4.07516 1.66667 3.3335 2.40834 3.3335 3.33334V16.6667C3.3335 17.5917 4.07516 18.3333 5.00016 18.3333H11.5085C11.0668 17.575 10.8335 16.7083 10.8335 15.8333C10.8335 13.075 13.0752 10.8333 15.8335 10.8333C16.1168 10.8333 16.3918 10.8583 16.6668 10.9V6.66667L11.6668 1.66667M10.8335 2.91667L15.4168 7.5H10.8335V2.91667Z"
                  fill="#73738D"
                />
              </svg>

              Scored Quiz
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"
            @click="goToConfiguration('translation')"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7252 12.5583L8.6085 10.4667L8.6335 10.4417C10.0835 8.82499 11.1168 6.96666 11.7252 4.99999H14.1668V3.33332H8.3335V1.66666H6.66683V3.33332H0.833496V4.99999H10.1418C9.5835 6.59999 8.70016 8.12499 7.50016 9.45832C6.72516 8.59999 6.0835 7.65832 5.57516 6.66666H3.9085C4.51683 8.02499 5.35016 9.30832 6.39183 10.4667L2.15016 14.65L3.3335 15.8333L7.50016 11.6667L10.0918 14.2583L10.7252 12.5583ZM15.4168 8.33332H13.7502L10.0002 18.3333H11.6668L12.6002 15.8333H16.5585L17.5002 18.3333H19.1668L15.4168 8.33332ZM13.2335 14.1667L14.5835 10.5583L15.9335 14.1667H13.2335Z"
                  fill="#73738D"
                />
              </svg>
              Translation
            </div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"
          >
            <div class="left-section">
              <svg
              class="mr-1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6668 1.66666H5.00016C4.07516 1.66666 3.3335 2.41666 3.3335 3.33332V16.6667C3.3335 17.5917 4.07516 18.3333 5.00016 18.3333H15.0002C15.9252 18.3333 16.6668 17.5917 16.6668 16.6667V6.66666L11.6668 1.66666ZM15.0002 16.6667H5.00016V3.33332H10.8335V7.49999H15.0002V16.6667ZM14.4585 8.33332L12.7085 15.8333H11.5418L10.0418 10.175L8.54183 15.8333H7.37516L5.54183 8.33332H6.79183L7.9585 14.0083L9.4585 8.33332H10.5418L12.0418 14.0083L13.2085 8.33332H14.4585Z"
                  fill="#73738D"
                />
              </svg>
              Watermark
            </div>
            <div class="right-section">
              <!-- <b-icon-chevron-right></b-icon-chevron-right> -->
              <div
                  class="show-logo custom-control custom-switch cursor-pointer"
                  @click="openShowLogoFreePlanModal"
                >
                <b-form-checkbox
        :id="`showLogo-toggle-${Date.now()}`"
        @change="updateShowLogoStatus()"
                    :disabled="getEditorSettings.isFreePlan"
                    v-model="showLogo"
                  ></b-form-checkbox>
                  
                </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center singleElement"  @click="changeCurrView('currency')"
          >
            <div class="left-section"> <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.8335 12.5H7.50016C7.50016 13.4 8.64183 14.1667 10.0002 14.1667C11.3585 14.1667 12.5002 13.4 12.5002 12.5C12.5002 11.5833 11.6335 11.25 9.80016 10.8083C8.0335 10.3667 5.8335 9.81667 5.8335 7.5C5.8335 6.00833 7.0585 4.74167 8.75016 4.31667V2.5H11.2502V4.31667C12.9418 4.74167 14.1668 6.00833 14.1668 7.5H12.5002C12.5002 6.6 11.3585 5.83333 10.0002 5.83333C8.64183 5.83333 7.50016 6.6 7.50016 7.5C7.50016 8.41667 8.36683 8.75 10.2002 9.19167C11.9668 9.63333 14.1668 10.1833 14.1668 12.5C14.1668 13.9917 12.9418 15.2583 11.2502 15.6833V17.5H8.75016V15.6833C7.0585 15.2583 5.8335 13.9917 5.8335 12.5Z" fill="#73738D"/>
</svg>
 Currency</div>
            <div class="right-section">
              <b-icon-chevron-right></b-icon-chevron-right>
            </div>
          </div>
        </div>
      </div>

      <div class="childView" v-else>
        <div class="backBtn" v-if="currView !== 'colorScheme'">
          <p
            class="d-flex align-items-center cursor-pointer"
            @click="changeCurrView('mainView')"
          >
            <b-icon-arrow-left class="mr-2"></b-icon-arrow-left>
            Back
          </p>
        </div>

        <div class="childrenElements">

        <ProgressBarComp v-if="currView == 'progressBar'"/>
        <AnimationComp v-if="currView == 'animation'"/>
        <FontComp v-if="currView == 'font'"/>
        <Layout v-if="currView == 'layout'"/>
        <CurrencyComp v-if="currView == 'currency'"/>
        <ColorScheme v-if="currView == 'colorScheme'" @handleColorBackButtonClick = "changeCurrView('mainView')" />
    </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapMutations,  mapGetters } from "vuex";
export default {
    components:{
        ProgressBarComp: () =>
      import(
        /* webpackChunkName: "ProgressBarComp" */ "./PageSettingsComponents/ProgressBarComp.vue"
      ),
      AnimationComp: () =>
      import(
        /* webpackChunkName: "AnimationComp" */ "./PageSettingsComponents/AnimationComp.vue"
      ),
      ColorScheme: () =>
      import(
        /* webpackChunkName: "ColorScheme" */ "./PageSettingsComponents/ColorScheme.vue"
      ),
      FontComp: () =>
      import(
        /* webpackChunkName: "FontComp" */ "./PageSettingsComponents/FontComp.vue"
      ),
      Layout: () =>
      import(
        /* webpackChunkName: "Layout" */ "./PageSettingsComponents/Layout.vue"
      ),
      CurrencyComp: () =>
      import(
        /* webpackChunkName: "CurrencyComp" */ "./PageSettingsComponents/CurrencyComp.vue"
      ),
    },
  data() {
    return {
      currView: "mainView",
      showLogo: null,
    };
  },
  computed:{
    ...mapGetters([
        "getEditorSettings"
    ])
  },
  methods: {
    ...mapMutations([
      "updateShowLogo",
      "setUpgradePlansModal",
      "setIsAnyPageEdited"
    ]),
    changeCurrView(payload) {
      if (payload) {
        this.currView = payload;
      }
    },
    goToConfiguration(selectedTab) {

      this.$router
          .push({
        name: "Connect",
        query: { selectedTab: selectedTab },
      })
          .then(() => { this.$router.go(0) })
    },
    openShowLogoFreePlanModal() {
      if (this.getEditorSettings.isFreePlan) {
        this.openUpgradePlansModalForWaterMark();
      }
    },
    openUpgradePlansModalForWaterMark() {
      let obj = {
        isOpen: true,
        title: "Upgrade to Remove Quizell Logo Watermark",
        subText: `Create a more streamlined and personalized quiz experience by removing the Quizell logo watermark. Upgrading your plan allows for a cleaner quiz interface, focusing solely on your content and brand.`,
      };

      this.setUpgradePlansModal(obj);
      return;
    },
    updateShowLogoStatus() {
      if (this.getEditorSettings.isFreePlan) {
        this.openUpgradePlansModalForWaterMark();
        return;
      }
      this.updateShowLogo(this.showLogo);
    },
    
  },

  watch:{
    showLogo(){
      this.setIsAnyPageEdited(true)
    }
  },
  mounted() {
    this.showLogo = this.getEditorSettings.showLogo ? true : false;
  },
};
</script>

<style scoped >
.pageSettingsMain {
  padding: 20px 10px;
}

.pageSettingsMain .pageSettingsTitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.settings-elements .singleElement {
  padding: 12px 0;
  border-bottom: 1px solid #f1f1f4;
  cursor: pointer;
}
.settings-elements .singleElement .left-section,
.childView .backBtn , .pageSettingHeader {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #73738d;
}
.settings-elements .singleElement .right-section {
  color: #d2d8e0;
}



</style>
